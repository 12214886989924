import React, { useEffect, useState } from "react";
import { createBrowserHistory } from "history";

import RouteList from "./routes";
import { useAuthStore } from "store/AuthStore";
import { activityControl, checkCustomerActivation } from "services/allServices";
import { useIdleTimer } from "react-idle-timer";
import { Button, TextField } from "components";
import { useToasts } from "react-toast-notifications";

const browserHistory = createBrowserHistory();

export default function App() {
  const [isLoggin, setIsLoggin, userInformations, setUserInformations] = useAuthStore((state) => [state.isLoggin, state.setIsLoggin, state.userInformations, state.setUserInformations]);

  const { addToast } = useToasts();

  const timeout = 1000 * 60 * 10 // 10 minutes
  const [isIdle, setIsIdle] = useState(localStorage.getItem("isAct") == "true" ? true : false);
  const [pass, setPass] = useState("");

  const handleOnActive = () => {
    setIsIdle(true);
    localStorage.setItem("isAct", true);
  };
  const handleOnIdle = () => setIsIdle(true);

  useIdleTimer({ timeout, onActive: handleOnActive, onIdle: handleOnIdle });


  const onActivityCheck = () => {
    const config = {
      password: pass

    };

    if (pass !== "") {
      activityControl(config).then(res => {
        if (res == true) {
          setIsIdle(false);
          localStorage.setItem("isAct", false);
        } else if (res?.message === "staff_not_found") {
          setIsLoggin(false);
          window.location.href = "/login";
          setIsIdle(false);
          localStorage.setItem("isAct", false);
        } else {
          addToast("Şifre Hatalı", {
            appearance: "error",
            autoDismiss: true
          });
        }
      }).catch(err => {
        onLogout()
      });
    }


  };

  const onLogout = () => {
    setIsLoggin(false);
    window.location.href = "/login";
    setIsIdle(false);
    localStorage.setItem("isAct", false);
  }

  useEffect(() => {
    if (isLoggin && userInformations) {
      checkCustomerActivation().then(res => {
        let newInfo = { ...userInformations };
        newInfo.customer_info.is_activated = res;
        setUserInformations(newInfo);
      });


    }


    if (localStorage.getItem("isAct") == "true") {
      setIsIdle(true);
      if (window?.location?.pathname == "/weblogin") {
        setIsIdle(false);


      } else {
        setIsIdle(true);

      }

    } else {
      setIsIdle(false);
    }


  }, []);
  useEffect(() => {
    if(window.location.host !== "panel.makdos.com"){
      setIsIdle(false);
    }
    if (!isLoggin) {
      setIsIdle(false);
    }
  }, [isIdle]);

  return (
    isIdle ?
      <div className="flex flex-col items-center justify-center w-full h-screen text-white bg-orangeBlur">
        <img src="/Logo.png" className="mb-6 lg:w-120 w-80" />
        <h1 className="text-3xl text-center lg:text-5xl">Uzun süre hareketsiz kaldınız!</h1>
        <h1 className="mt-4 mb-8 text-lg text-center lg:text-2xl">Lütfen şifrenizi tekrar giriniz.</h1>
        <TextField
          type="password"
          name="password"
          id="password"
          value={pass}
          onChange={setPass}
          className="relative"
          label={"Parola"}
          placeholder={"Parola"}
          autoComplete="off"
          onKeyPress={onActivityCheck}

        />
        <div>
          <Button basket onClick={() => onActivityCheck()} className="mt-4 w-32 !hover:bg-gray-400">
            Gönder
          </Button>
        </div>
        <div onClick={() => onLogout()} className="flex items-center mt-4 space-x-1 cursor-pointer group">
          <p className="text-lg font-semibold text-white duration-300 group-hover:text-alert-red"> Çıkış Yap</p>
          < i className="text-lg duration-300 ri-login-box-line group-hover:text-alert-red" />


        </div>
      </div>

      :
      <RouteList />


  );
}
